<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewContainerForm"
        ref="addNewContainerForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitNewContainerForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nr kontenera"
              v-model="container.refNumber"
              :rules="[rules.required]"
              placeholder="Wpisz numer kontenera"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Nr seryjny kontenera"
              v-model="container.serialNumber"
              placeholder="Wpisz numer seryjny kontenera"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="departmentsList"
              :rules="[rules.required]"
              label="Oddział"
              v-model="container.departmentId"
              item-text="name"
              item-value="id"
              placeholder="Wybierz oddział"
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="containerTypes"
              :rules="[rules.required]"
              label="Typ kontenera"
              v-model="container.containerTypeId"
              item-text="name"
              item-value="id"
              placeholder="Wybierz typ kontenera"
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              :rows="3"
              auto-grow
              label="Dodatkowe informacje"
              v-model="container.notes"
              placeholder="Wpisz dodatkowe informacje o kontenerze"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewContainerForm"
      >
        Dodaj kontener
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      isFormValid: true,
      container: {
        refNumber: null,
        serialNumber: null,
        notes: null,
        containerTypeId: null,
        departmentId: null,
      }
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      departmentsList: state => state.departments.items,
      containerTypes: state => state.containerTypes.items,
      isProcessing: state => state.containers.isProcessing
    })
  },
  mounted () {
    this.container.departmentId = this.department.id
  },
  methods: {
    ...mapActions({
      addNewContainer: 'containers/addNewItem',
      getContainers: 'containers/getItems',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    submitNewContainerForm () {
      this.$refs.addNewContainerForm.validate()
      if (this.isFormValid) {
        this.addNewContainer({ params: this.container })
          .then(() => {
            this.getContainers()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
